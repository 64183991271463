import {TRIAL_ACCESS_AVAILABLE} from '@/store/getterTypes';

export default {
  isLogged: (state) => {
    return Boolean(state.user);
  },
  [TRIAL_ACCESS_AVAILABLE]: (state) => {
    return !state.user?.trial_period_used;
  },
};

