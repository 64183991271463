import {
  EVENT_TYPE,
  ORDER_ACTIVITY_STATUS,
  ORDER_STATUS,
  ORDER_TYPE,
  SUBSCRIPTION_PERIODS,
} from '@/const';
import api from '../../api/api';
import {teamName} from '@/utils/helpers';
import Vue from 'vue';
import {GET_INFO_FOR_ORDER_BY_TYPE, ORDER_PAY} from '../actionTypes';
import {eventTeams} from '@/league/components/EventCards/eventMethods';

const BASE_PRICE_FIELD_NAME = 'base_price';
const FEE_PRICE_FIELD_NAME = 'service_fee';

export default {
  [GET_INFO_FOR_ORDER_BY_TYPE]: async (state, params) => {
    const {
      type,
      id, // package_id, event_id, subscription_id
      single_event_package_id: singleId,
      participant_id: participantId,
      event_category_id: categoryId,
    } = params;

    if (!id) {
      return Promise.reject(new Error('id not selected'));
    }

    if (type === ORDER_TYPE.event) {
      const promise = Promise.allSettled([
        api.eventsApi.get_event(id),
        singleId && api.paymentApi.package(singleId),
      ]);
      return promise.then(([{
        value: event,
        reason: eventReason,
      }, {value: pack}]) => {
        if (eventReason || (event.data?.type === EVENT_TYPE.paid && !singleId)) {
          throw new Error(JSON.stringify(eventReason));
        }

        const isPaid = event?.data?.type === EVENT_TYPE.paid;
        const paymentData = isPaid ? pack?.data : event?.data;
        const teams = eventTeams(event.data);

        const timeObj = Vue.prototype.getFormattedDate({
          ts: event.data.event_utc_ts,
          timeZone: event.data.time_zone,
          formatsOptions: {
            month: {month: 'short'},
            day: {day: '2-digit'},
            year: {year: 'numeric'},
            hh: {
              hour: '2-digit',
              hour12: false,
              minute: '2-digit',
            },
          },
        });
        const details = {
          time: `${timeObj.month} ${timeObj.day} ${timeObj.year} ${timeObj.hh}`,
          packageName: isPaid? paymentData.name : '',
        };

        let name;
        if (event.data.participants.length === 2) {
          name = `${teamName(teams.awayTeam.name)} @ ${teamName(teams.homeTeam.name)}`;
          details.gender = teams.awayTeam.gender;
          details.sport = teams.awayTeam.sport;
          details.team = `${teams.awayTeam.name} & ${teams.homeTeam.name}`;
        } else {
          name = event.data.name;
        }

        const orderData = {
          price: paymentData?.price,
          basePrice: paymentData?.[BASE_PRICE_FIELD_NAME] || paymentData?.price,
          fee: paymentData?.[FEE_PRICE_FIELD_NAME] || 0,
          name,
          event_utc_ts: event.data.event_utc_ts,
          exist: event.data.has_access,
        };
        singleId && (orderData.single_event_package_id = singleId);

        const trackData = {
          type: `${ORDER_TYPE.event}${!isPaid ? ' - ' + paymentData.type : ''}`,
          name: isPaid ? `${event?.data.name} [package: ${pack?.data?.name}]` : event?.data.name,
          id: isPaid ? `${event?.data.id} [package: ${pack?.data?.id}]` : event?.data.id,
          item: paymentData,
          tax: paymentData?.service_fee,
          details,
          event: event.data,
        };
        return {
          trackData,
          orderData,
        };
      });
    } else if (type === ORDER_TYPE.package) {
      const promise = Promise.allSettled([
        api.paymentApi.package(id),
        api.paymentApi.orders({
          type: ORDER_TYPE.package,
          status: ORDER_STATUS.paid,
        }),
      ]);

      return promise.then(([{
        value: packageData,
        reason: packReason,
      }, {value: orders}]) => {
        if (packReason) {
          throw new Error(JSON.stringify(packReason));
        }
        const pack = packageData?.data;

        const packageEventCategoryArray = Object.keys(pack.event_categories);
        const searchingCategoryId = packageEventCategoryArray.length === 1 ?
            packageEventCategoryArray[0] :
            categoryId;

        const exist = orders?.data?.find((item) => {
          if (item.product && (Number(item.product.id) === Number(id))) {
            if (pack.is_team_pass) {
              return item.product.participant_id === Number(participantId);
            }
            return item.product.event_category_id === Number(searchingCategoryId);
          }
        });

        if (!exist && pack.is_team_pass && !participantId) {
          throw new Error('Package is TeamPass but team not selected');
        }

        const trackData = {
          type: ORDER_TYPE.package,
          name: pack.name,
          id: pack.id,
          item: pack,
          tax: pack.service_fee,
        };

        const orderData = {
          exist,
          price: pack.price,
          basePrice: pack?.[BASE_PRICE_FIELD_NAME] || pack?.price,
          fee: pack?.[FEE_PRICE_FIELD_NAME] || 0,
          name: pack?.name,
        };

        return {
          trackData,
          orderData,
        };
      });
    } else if (type === ORDER_TYPE.subscription) {
      const subscribePromise = Promise.allSettled([
        api.paymentApi.subscription(id),
        api.paymentApi.orders({
          type: ORDER_TYPE.subscription,
          status: ORDER_STATUS.paid,
          activity_status: ORDER_ACTIVITY_STATUS.active,
        }),
      ]);

      return subscribePromise.then(([{
        value: sub,
        reason: subReason,
      }, {value: orders}]) => {
        if (subReason) {
          throw new Error(JSON.stringify(subReason));
        }
        const subscription = sub?.data;
        const exist = orders?.data?.find((item) => {
          return !!(item['product'] && (Number(item['product'].id) === Number(id)));
        });

        const trackData = {
          type: ORDER_TYPE.subscription,
          name: subscription?.name,
          id: subscription?.id,
          item: subscription,
          tax: subscription.service_fee,
        };

        const orderData = {
          price: subscription?.price,
          basePrice: subscription?.[BASE_PRICE_FIELD_NAME] || subscription?.price,
          fee: subscription?.[FEE_PRICE_FIELD_NAME] || 0,
          exist,
          name: subscription?.name,
          period: subscription?.offer === SUBSCRIPTION_PERIODS.ANNUAL ? 'annual' : 'monthly',
          trialPeriod: subscription?.trial_period,
        };

        return {
          trackData,
          orderData,
        };
      });
    }
  },
  [ORDER_PAY]: () => {
    let interval;
    const clearIntervalCb = () => {
      clearInterval(interval);
    };

    /**
     *
     * @param {number} orderId
     * @return {Promise<unknown>}
     */
    function orderCheck(orderId) {
      return new Promise((resolve, reject) => {
        interval = setInterval(() => {
          api.paymentApi.orderById({order_id: orderId}).then((res) => {
            if (res?.data?.status === ORDER_STATUS.paid) {
              resolve(res);
              clearIntervalCb();
            } else if (res?.data?.status === ORDER_STATUS.failed) {
              reject({
                ...res?.data,
                popup: true,
              });
              clearIntervalCb();
            }
          }).catch((err) => {
            reject(err);
            clearIntervalCb();
          });
        }, 2000);
      });
    }

    return {
      orderPromise: orderCheck,
      clearIntervalCb,
    };
  },
};
